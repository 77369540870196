import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'BizNation',
    useCase: 'BRANDING, GRAPHIC DESIGN, WEB DESIGN, WEB DEVELOPMENT, WEB APP DEVELOPMENT',
    description: `BizNation is a brand and online application we designed and developed from the ground up. The cost of legal fees when attempting to raise money is extremely high ranging between $4,000 and $8,000 dollars. The BizNation platform solves this issue by giving the user a simple fill in the blank approach to quickly enter their information and generate the documents need to raise capital. The user can pick from two price levels Basic or Premium. The Premium level gives the user 2 hours of legal counsel on their created document.
    We also designed a group of characters that can give life to a normally boring process of legal document creation. `,
    link: 'convertiblenote.biznation.io',
  },
  caseId: 'biznation',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
    'image11.jpg',
    'image12.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
